import React, {Component} from 'react';
import {Container,Col} from 'reactstrap';
import { logInWithEmailAndPassword } from '../services/firebase';

export default class AdminLogin extends Component{
    constructor(props){
        super(props);
        this.login=this.login.bind(this);
        this.handleChange= this.handleChange.bind(this);
        this.state = {
            email: '',
            passowrd:'',
            loggedIn: false,
            user:null
        }
    }

    login(e){
        e.preventDefault();
        logInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
            // var data = {
            //     session:u.user,
            // }
            // postData(JSON.stringify(data), 'finesse/StoreSession.php')
            //sessionStorage.setItem("usermetadata",u.user.ra);
            console.log(u)
            this.props.history.push({
                pathname: '/admin-edit',
               authenticated:true
              })

              
        }).catch((error) => {
            console.log(error);
        });
    }

    handleChange(e){
        this.setState({ [e.target.name] :e.target.value});
    }

render(){
    return(
        <Container>
        <Col>
        <form>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input value={this.state.email} onChange={this.handleChange} type ="email" name ="email"
                className="form-control" id="exampleInputEmail1" aria-describedby = "emailHelp"
                placeholder="Enter Email" />
                <small id ="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div>
                <label htmlFor="exampleInputPassword1">Password</label>
                <input value={this.state.password} onChange={this.handleChange} type="password"
                name="password" className="form-control" id="exampleInputPassword1" placeholder="Password"/>
            </div>
            <button type="submit" onClick={this.login} className="btn btn-primary">Login</button>
        </form>
        </Col>
        </Container>
    )
}

}